import React from 'react'
import { Fade } from 'react-awesome-reveal'
import Layout from '../components/layout'
import { Section } from '../components/section'
import Seo from '../components/seo'

const WelcomeSubPage = () => {
	return (
		<Layout>
			<Seo
				title="Welcome to Rapid Fire Options!"
				description="Thank you for signing up for Rapid Fire Options Premium Service."
			/>
			<Section sectionTitle="Welcome to Rapid Fire Options">
				<Fade triggerOnce>
					<div className="py-10">
						<p>
							Thank you for subscribing to Rapid Fire Options! You will receive
							an email shortly with all the details to get set up. If you do not
							receive an email within an hour, please send us an email at{' '}
							<a
								className="text-primary"
								href="mailto:subscribe@rapidfireoptions.com">
								subscribe@rapidfireoptions.com
							</a>
							.
						</p>
						<br />
						<p className="pb-5">
							Thank you again for joining. We look forward to your success!
						</p>
						<p>
							Sincerely,
							<br />
							The Rapid Fire Options team
						</p>
					</div>
				</Fade>
			</Section>
		</Layout>
	)
}

export default WelcomeSubPage
